import { ApolloProvider } from "@apollo/client";
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import apolloClient from "./services/apollo/client";

import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
import "./index.scss";
import { store } from "./services/redux/storeConfig/store";
import * as serviceWorker from "./services/workers/serviceWorker";
import { Layout } from "./services/contexts/Layout";

const LazyApp = lazy(() => import("./App"));

// configureDatabase()
ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <ToastProvider autoDismiss autoDismissTimeout={6000}>
          <Layout>
            <LazyApp />
          </Layout>
        </ToastProvider>
      </Suspense>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
