import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import 'moment/locale/es';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { useToasts } from "react-toast-notifications";
import { Alert, Card, CardBody, Col, Media, Row, Spinner } from 'reactstrap';
import { importAll } from '../../../services/helpers/utils';
import { store } from '../../../services/redux/storeConfig/store';
import UseIcon from '../useIcon';
import { NOTIFICATION_CATEGORIES } from './notificationUtils';
const _ = require('lodash');
moment.locale('es');

const Notifications = (props = {}) => {
    //Consts&states
    const { handleSuccessMarkAllAsRead, handleShowNotification } = props;
    const { addToast } = useToasts();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
    const [redirectTo, setRedirectTo] = useState("");
    const userId = store.getState().auth.session.data.user_id;
    const imageRefs = importAll(require.context('../../../assets/img/icons/notifications', false, /\.(png|jpe?g|svg)$/));

    //queries&mutations
    const GET_ITEMS = gql`
		query{
			obtenerNotificaciones(
				where:{
					destinatarioId:{is:"${userId}"}
					tipo:{is:"ESTANDAR"}
				}
                sort:{
                    field:"created_at",sort:DESC
                }
                pagination:{
                    page:1,size:100
                }
			){
                items{
                    id
                    titulo
                    descripcion
                    link
                    leida
                    createdAt
                    categoria{
                        slug
                    }
                }
			}
		}
	`;

    const MARK_ALL_AS_READ = gql`
        mutation marcarLeidasNotificaciones($destinatarioId:String!){
            marcarLeidasNotificaciones(destinatarioId:$destinatarioId)
        }
    `;

    const UPDATE_ENTITY = gql`
        mutation actualizarNotificacion($notificacionId:String!,$input:ActualizarNotificacionInput!){
            actualizarNotificacion(notificacionId:$notificacionId,input:$input){
                id
            }
        }
    `;
    
    //useQueries&useMutations
    const [executeQuery, { data:dataNotifications, loading:loadingNotifications, error:errorNotifications, refetch}] = useLazyQuery(GET_ITEMS, {fetchPolicy: 'network-only', nextFetchPolicy: 'cache-first', pollInterval:10000});
    const [markAllAsReadMutation] = useMutation(MARK_ALL_AS_READ);
    const [updateEntity] = useMutation(UPDATE_ENTITY);

    //effects
    useEffect(() => {
		executeQuery();
	},[]);

    useEffect(() => {
		if(!loadingNotifications
		&& !errorNotifications
		&& dataNotifications?.obtenerNotificaciones){
			setLoading(false);
			setError(false);
			setItems(arrangeItems(dataNotifications.obtenerNotificaciones.items));
		}else if(errorNotifications){
			setLoading(false);
			setError(true);
		}else if(loadingNotifications){
            setLoading(true);
            setError(false);
        }
	}, [dataNotifications, loadingNotifications, errorNotifications]);


    //arrangeData
    const arrangeItems = (dataItems) => {
		const response = [];
		if (dataItems && dataItems.length > 0) {
			dataItems.forEach((dataItem) => {
                let categoryData = null;
                let imageName = "notificacion_default";
                let image = null;
                let titleTruncated = dataItem.titulo?.length > 25 ? `${dataItem.titulo.substring(0,25)}...`: dataItem.titulo;
                let descriptionTruncated = dataItem.descripcion?.length > 35 ? `${dataItem.descripcion.substring(0,35)}...`: dataItem.descripcion;
                let timeAgo = "";
                Object.keys(NOTIFICATION_CATEGORIES).forEach((subjectKey) => {
					if(NOTIFICATION_CATEGORIES[subjectKey].slug === dataItem?.categoria?.slug){
						categoryData = NOTIFICATION_CATEGORIES[subjectKey];
					}
				});
                if(categoryData?.imageName){
                    imageName = categoryData.imageName;
                }
                if(imageRefs){
                    imageRefs.forEach((imageRef) => {
                        if(imageRef.includes(imageName)){
                            image = imageRef;
                        }
                    });
                }
                if(dataItem.createdAt){
                    timeAgo = moment(dataItem.createdAt).fromNow();
                }
                response.push({
                    ...dataItem,
                    titleTruncated: titleTruncated,
                    descriptionTruncated: descriptionTruncated,
                    image:image,
                    timeAgo:timeAgo
                });
			});
		}
		return response;
	}

    //handleFunctions
    const handleMarkAllAsRead = async() => {
        const request = {
            destinatarioId:userId
        }
        const response = await markAllAsReadMutation({variables: request});
        if(response && (response.data && (response.data.marcarLeidasNotificaciones))){
            refetchNotifications();
        }else{
            addToast("Error al intentar marcar todas las notificaciones como leídas", {appearance: "error"});
        }
	}
    const handleItem = async (props = {}) => {
        const {e, item = {}} = props;
        e.preventDefault();
        e.stopPropagation();
        if(item.id){
            let response = null;
			let errorResponse = false;
			let objectRefResponse = "actualizarNotificacion";
            try{
                if(!item.leida){
                    response = await updateEntity({
                        variables:{
                            notificacionId:item.id,
                            input:{ leida: true }
                        }
                    });
                    if(response && (response.data && (response.data[objectRefResponse] && (response.data[objectRefResponse].id && response.data[objectRefResponse].id!=="")))){
                        refetchNotifications();
                    }else{
                        errorResponse = true;
                    }
                }
            }catch(err){
				console.log(err);
				errorResponse = true;
			}
            if(!errorResponse){
                if(item.link){
                    setRedirectTo(`/${item.link}`);
                }else{
                    handleShowNotification({id:item.id, titulo: item.titulo})
                }
            }
        }
    }
    const handleViewAll = () => {
        setRedirectTo('/notifications');
    }
    
    const refetchNotifications = () => {
        refetch();
        if (handleSuccessMarkAllAsRead && (typeof handleSuccessMarkAllAsRead === "function")) {
            handleSuccessMarkAllAsRead();
        }
    }

    //renders
    if (redirectTo) {
        window.location.href = redirectTo;
        //return <Redirect push to={redirectTo} />;
    }

    return (
        <Row>
            {(loading) ? (
                <Col className='text-center my-4'>
                    <Spinner color="primary" size="lg" />
                </Col>
            ) : (error) ? (
                <Card>
                    <CardBody style={{lineHeight:"initial"}}>
                        <Alert color="danger">
                            <div className="alert-heading">Error</div>
                            Lo sentimos, hubo un problema al intentar obtener la información de esta sección, favor de intentarlo más tarde o ponerse en contacto con el proveedor del sistema.
                        </Alert>
                    </CardBody>
                </Card>
            ) : items?.length>0 ? (
                <>
                    <Col sm="12" className='my-1'>
                        <div className="d-flex justify-content-between">
                            <span className='text-primary cursor-pointer ml-1' onClick={handleViewAll}>Ver Todas</span>
                            <span className='text-primary cursor-pointer mr-1' onClick={handleMarkAllAsRead}>Marcar todas como leídas</span>
                        </div>
                    </Col>
                    <Col sm="12">
                        <PerfectScrollbar
                            className="media-list overflow-hidden position-relative"
                            options={{
                                wheelPropagation: false
                            }}
                        >
                            {items?.map((item) => (
                                <div className="d-flex justify-content-between" style={!item.leida ? {backgroundColor:'#ededed'} : {}} onClick={(e) => {handleItem({e,item})}}>
                                    <Media className="d-flex align-items-start">
                                        <Media left>
                                            <img src={item.image} className='font-medium-5 primary' style={{maxHeight:'40px',maxWidth:'40px'}}/>
                                        </Media>
                                        <Media body>
                                            <Media heading className="primary media-heading" tag="h6">
                                                {item.titleTruncated}
                                            </Media>
                                            <p className="notification-text">
                                                {item.descriptionTruncated}
                                            </p>
                                        </Media>
                                        <small>
                                            <time className="media-meta" dateTime={item.createdAt} >
                                                {item.timeAgo}
                                            </time>
                                        </small>
                                    </Media>
                                </div>
                            ))}
                        </PerfectScrollbar>
                    </Col>
                </>
            ) : (
                <Col className='text-center my-4'>
                    <UseIcon name="checkCircle" adjust="text-muted" size={20} />
                    <span className='ml-1 text-muted'>Sin Notificaciones</span>
                </Col>
            )}
        </Row>
    );
};

export default Notifications;