
export const NOTIFICATION_CATEGORIES = {
    birthday:{
        slug:"notificacion_categoria_cumpleanios",
        imageName:"notificacion_categoria_cumpleanios",
        imageExtension:"png"
    },
    events:{
        slug:"notificacion_categoria_eventos",
        imageName:"notificacion_categoria_eventos",
        imageExtension:"png"
    },
    reminders:{
        slug:"notificacion_categoria_recordatorios",
        imageName:"notificacion_categoria_recordatorios",
        imageExtension:"png"
    },
    actions:{
        slug:"notificacion_categoria_acciones",
        imageName:"notificacion_categoria_acciones",
        imageExtension:"png"
    },
    vacations:{
        slug:"notificacion_categoria_solicitudes_vacaciones",
        imageName:"notificacion_categoria_solicitudes_vacaciones",
        imageExtension:"png"
    },
    payrolls:{
        slug:"notificacion_categoria_nominas",
        imageName:"notificacion_categoria_nominas",
        imageExtension:"png"
    },
    imports:{
        slug:"notificacion_categoria_importaciones",
        imageName:"notificacion_categoria_importaciones",
        imageExtension:"png"
    }
};

export const NOTIFICATION_STATUSES = {
    progress:{
        slug:"notificacion_estatus_proceso_en_proceso",
        styleColor:"#105D96",
        label:"Pendiente",
        color:"primary",
    },
    success:{
        slug:"notificacion_estatus_proceso_exitoso",
        styleColor:"#4BB543",
        label:"Exitoso",
        color:"success",
    },
    failed:{
        slug:"notificacion_estatus_proceso_fallido",
        styleColor:"#A50203",
        label:"Fallido",
        color:"danger",
    },
};
