import { gql, useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { connect } from "react-redux";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import default_avatar from "../../../../assets/img/icons/default_avatar.jpg";
import ConfirmationModalCallBack from "../../../../components/nominex/ConfirmationModalCallBack";
import UseNotifications from "../../../../components/nominex/Notifications";
import UseIcon from "../../../../components/nominex/useIcon";
import NotificationView from "../../../../views/pages/notifications/NotificationView";
import { history } from "../../../../services/helpers/history"
import { store } from "../../../../services/redux/storeConfig/store";

const mapStateToProps = (state) => {
	return {
		session: state.auth.session.data,
		user: state.auth.session.data.role_slug,
		permission: state.auth.session.data.permissions,
	};
};

const UserDropdown = (props) => {
	return (
		<DropdownMenu right>
			<DropdownItem divider />
			<DropdownItem tag="a" href="#" onClick={(e) => history.push("/auth/login")} >
				<Icon.Power size={14} className="mr-50" />
				<span className="align-middle">Salir</span>
			</DropdownItem>
		</DropdownMenu>
  );
};

const UserWidget = (props = {}) => {
	//Consts&states
	const [notificationsProps, setNotificationsProps] = useState({numberNotificationsNoReaded:0,showNotifications:false});
	const [confirmationModal, setConfirmationModal] = useState({isOpen:false,title:"",text:"",ref:"",data:null});
	const userId = store.getState().auth.session.data.user_id;

	//queries&mutations
	const GET_ITEMS = gql`
		query{
			obtenerNotificaciones(
				where:{
					destinatarioId:{is:"${userId}"}
					tipo:{is:"ESTANDAR"}
					leida:{is:"false"}
				}
			){
				items{
					id
				}
			}
		}
	`;

	//useQueries&useMutations
	const [executeQuery, { data:dataNotifications, loading:loadingNotifications, error:errorNotifications, refetch}] = useLazyQuery(GET_ITEMS, {fetchPolicy: 'network-only', nextFetchPolicy: 'cache-first',pollInterval:10000});

	//effects
	useEffect(() => {
		executeQuery();
	},[]);

	useEffect(() => {
		if(dataNotifications && dataNotifications.obtenerNotificaciones){
			let notificationsPropsClone = {...notificationsProps};
			notificationsPropsClone.numberNotificationsNoReaded = dataNotifications.obtenerNotificaciones?.items?.length>0 ? dataNotifications.obtenerNotificaciones.items.length : 0;
			setNotificationsProps(notificationsPropsClone);
		}
	}, [dataNotifications, loadingNotifications, errorNotifications]);

	//handleFunctions
	const handleToggle = () => {
		let notificationsPropsClone = {...notificationsProps};
		notificationsPropsClone.showNotifications = !notificationsPropsClone.showNotifications;
		setNotificationsProps(notificationsPropsClone);
	}

	const handleSuccessMarkAllAsRead = () => {
		refetch();
	}

	const handleShowNotification = (props = {}) => {
		const {id, titulo} = props;
		setConfirmationModal({
			ref:id,
			isOpen:true,
			title:titulo,
			text: <NotificationView id={id} />,
		});
	}

	const handleModal = async (actionModal) => {
		setConfirmationModal({...confirmationModal,isOpen:false});
		refetch();
	}

	//arrangeData

	//renders
	return (
		<>
			<ul className="nav navbar-nav navbar-nav-user float-right">
				<UncontrolledDropdown tag="li" className="dropdown-notification nav-item" onToggle={handleToggle} >
					<DropdownToggle tag="a" className="nav-link nav-link-label">
						<UseIcon name="bell" size={21} />
						{notificationsProps?.numberNotificationsNoReaded>0 && (
							<Badge pill color="primary" className="badge-up">
								{` ${notificationsProps.numberNotificationsNoReaded} `}
							</Badge>
						)}
					</DropdownToggle>
					<DropdownMenu tag="ul" right className="dropdown-menu-media">
						<li className="dropdown-menu-header">
							<div className="dropdown-header mt-0">
								<h3 className="text-white">{` ${notificationsProps.numberNotificationsNoReaded} Nueva${notificationsProps.numberNotificationsNoReaded>1 ? 's' : ''}`}</h3>
								<span className="notification-title">{`Notificaci${notificationsProps.numberNotificationsNoReaded>1 ? 'ones' : 'ón'} Nominex`}</span>
							</div>
						</li>
						{notificationsProps?.showNotifications && (
							<UseNotifications handleSuccessMarkAllAsRead={handleSuccessMarkAllAsRead} handleShowNotification={handleShowNotification} />
						)}
					</DropdownMenu>
				</UncontrolledDropdown>

				<UncontrolledDropdown tag="li" className="dropdown-user nav-item">
					<DropdownToggle tag="a" className="nav-link dropdown-user-link">
						<div className="user-nav d-sm-flex d-none">
							<span className="user-name text-bold-600">
								{props.session.name}
							</span>
							<span className="user-status">{props.session.role}</span>
						</div>
						<span data-tour="user">
							<img
								src={props.session.avatar ? props.session.avatar : default_avatar}
								className="round"
								height="40"
								width="40"
								alt="avatar"
							/>
						</span>
					</DropdownToggle>
					<UserDropdown {...props} />
				</UncontrolledDropdown>
			</ul>
			<ConfirmationModalCallBack
				handleModal={(actionModal) => handleModal(actionModal)}
				title={confirmationModal.title}
				isOpen={confirmationModal.isOpen}
				children={confirmationModal.text}
				noFotter={true}
				/>
		</>
  );
};

const NavbarUser = connect(mapStateToProps)(UserWidget);

export default NavbarUser;
