import { gql, useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Alert, Card, CardBody, Col, Row, Spinner } from "reactstrap";

const NotificationView = (props = {}) => {
	//Consts&states
	const {id = null} = props;
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [notificationData, setNotificationData] = useState(null);

	//queries&mutations
	const GET_ENTITY = gql`
		query{
			obtenerNotificacion(notificacionId:"${id}"){
				id
				leida
				descripcion
			}
		}
	`;

	const UPDATE_ENTITY = gql`
		mutation actualizarNotificacion($notificacionId:String!,$input:ActualizarNotificacionInput!){
			actualizarNotificacion(notificacionId:$notificacionId,input:$input){
				id
			}
		}
	`;

	//handleFunctions
	const [executeQueryEntity, { data: dataEntity, loading: loadingEntity, error: errorEntity }] = useLazyQuery(GET_ENTITY, { fetchPolicy: 'network-only', nextFetchPolicy: 'cache-and-network' });
	const [updateEntity] = useMutation(UPDATE_ENTITY);

	//effects
	useEffect(() => {
		if(id){
			executeQueryEntity();
		}
	},[id]);

	useEffect(() => {
		if(!loadingEntity
		&& !errorEntity
		&& dataEntity){
			setLoading(false);
			setError(false);
			setNotificationData((dataEntity && dataEntity.obtenerNotificacion)?dataEntity.obtenerNotificacion:null);
		}else if(errorEntity){
			setLoading(false);
			setError(true);
		}
	}, [dataEntity, loadingEntity, errorEntity]);

	useEffect(() => {
		if(notificationData && notificationData.id && !notificationData.leida){
			markAsRead({id});
		}
	},[notificationData]);

	//arrangeData

	//handleFunctions
	const markAsRead = async (props = {}) => {
		const {id} = props;
		if(id){
			let response = null;
			let errorResponse = false;
			let objectRefResponse = "actualizarNotificacion";
			try {
				response = await updateEntity({
					variables:{notificacionId:id,input:{
						leida:true
					}}
				});
				if(response && (response.data && (response.data[objectRefResponse] && (response.data[objectRefResponse].id && response.data[objectRefResponse].id!=="")))){
					//OK
				}else{
					errorResponse = true;
				}
			}catch(err){
				console.log(err);
				errorResponse = true;
			}
			if(errorResponse){
				addToast("Lo sentimos, hubo un error al intentar marcar como leída la notificación, favor de intentarlo más tarde o ponerse en contacto con el proveedor del sistema.", {appearance: "error"});
			}
		}
	}

	//Response
	return (
		<React.Fragment>
			<Row>
				<Col sm="12">
					{(loading) ? (
						<Card>
							<CardBody className='text-center'>
								<Spinner color="primary" size="lg" />
							</CardBody>
						</Card>
					) : error ? (
						<Card>
							<CardBody>
								<Alert color="danger">
									<div className="alert-heading">Error</div>
									Lo sentimos, hubo un problema al intentar obtener la información de esta sección, favor de intentarlo más tarde o ponerse en contacto con el proveedor del sistema.
								</Alert>
							</CardBody>
						</Card>
					) : (
						<Card>
							<CardBody>
								<span>{notificationData?.descripcion}</span>
							</CardBody>
						</Card>
					)}
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default NotificationView;
